export const Colors = {
  white: '#FDFFFC',
  background: '#282C34',
  titleCardBackground: '#665F6B',
  cardBackground: '#685D6F',
  cardLink: '#C0E1F1',
  footerBackground: '#636363',
  border: '#D1D1D1',
  lightPurple: '#5C61FF',
  lightBlue: '#C4E4F8',
  darkBlue: '#10689c',
  tanPink: '#C3716F',
  millenialPink: '#ffb6c1',
  red: '#D1001C',
};
